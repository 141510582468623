.location-selector-popup {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 10px 10px 0 0; /* Round the top corners only */
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  margin: auto;
  position: fixed;
  bottom: 0; /* Position the popup at the bottom */
  left: 50%;
  transform: translate(-50%, 0); /* Center horizontally */
  z-index: 1000;
  width: 90%; /* Occupy 90% of the width */
  height: 85%; /* Occupy 85% of the page height */
  overflow-y: auto; /* Allow scrolling if content overflows */
}

.location-selector-popup .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.location-selector-popup .header h3{
  color: #888;
}

.location-selector-popup .button-group {
  display: flex;
  align-items: center;
}

.btn {
  padding: 0.6rem 0.8rem;
  font-size: 1.2rem;
  text-align: center;
  border: 0;
  outline: none;
  border-radius: 10px;
  margin: 10px 5px;
  box-shadow: 1px -3px 52px -5px rgba(0, 0, 0, 0);
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}

.small-btn {
  font-size: 1rem;
  padding: 0.4rem 0.6rem;
  min-width: 80px;
}

.telegram-button {
  background-color: var(--button-color);
  color: var(--button-text-color);
}

.location-selector-popup .location-input {
  width: 100%;
  padding: 0.5rem;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.location-selector-popup .phone-number-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.location-selector-popup .country-code {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  background-color: #f1f1f1;
}

.location-selector-popup .phone-input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 5px 5px 0;
}

.location-selector-popup .phone-tags {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.location-selector-popup .phone-tag {
  padding: 0.4rem 0.8rem;
  background-color: #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.location-selector-popup .phone-tag:hover {
  background-color: #858282;
}

.location-selector-popup .last-saved-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.location-selector-popup .map-container {
  margin-top: 20px;
  height: 400px;
}

.location-selector-popup .last-saved-label {
  margin-right: 10px;
  flex-shrink: 0;
  color: #888;
}

.location-selector-popup .location-dropdown {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  flex-grow: 1;
  color: var(--hint-color);
  outline: none;
  padding-right: 1rem;
}
.location-selector-popup .location-dropdown:focus{
  outline: none;
}
.close-button {
    color: var(--hint-color);
}
.location-option{
  color: var(--hint-color)
}
