.item-card {
    display: flex;
    gap: 10px;
    width: 80%;
    border: 2px solid var(--button-color);
    padding: 10px;
    border-radius: 5px;
    background-color: var(--background-color);
}

.item-image  {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    padding: 10px;
}

.item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--text-color);
    gap: 5px;
}