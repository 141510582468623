
.iframe-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .iframe-popup iframe {
    width: 80%;
    height: 80%;
  }
  
  .close-iframe-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    z-index: 1100;
    border-radius: 50%;
  }
  