.history_cards__container{
    width: 98%;
    display: flex;
    flex-flow: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 5px;
    padding-top: 80px;
}
.title {
    color: var(--button-color);
    text-align: center;
}


/* css for paginations */

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
    padding: 5px;
}

.pagination-controls button {
    padding: 5px 10px;
    border: none;
    background-color: var(--button-color);
    color: var(--button-text-color);
    cursor: pointer;
    border-radius: 5px;
}

.pagination-controls span {
    color: var(--text-color);
}

.pagination-controls button:disabled {
    background-color: var(--button-color);
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}