.history_card {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    width: 80%;
    height: auto;
    border: 2px solid var(--button-color);
    padding: 10px;
    border-radius: 5px;
    background-color: var(--background-color);
}

.history_card .card-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--text-color);
    gap: 5px;
}

.history_card .card-left span{
    color: var(--text-color);
    font-size: 13px;
}

.history_card .card-right {
    display: flex;
    align-items: center;
    width: 100%;
}
.history_card .card-right a{
    display: block;
    width: 100%;
}

.history_card .card-right .view-button {
    padding: 12.5px 10px;
    background-color: var(--button-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

