.bottom-sheet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}


.bottom-sheet {
  background-color: var(--background-color);
  width: 100%;
  max-height: 66%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative; /* Added for positioning the done button */
}

.bottom-sheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  color: var(--text-color);
}

.close-btn {
  font-size: 24px;
  cursor: pointer;
}

/* .bottom-sheet-content {
  padding: 16px;
  flex: 1;
  overflow-y: auto;
} */

.bottom-sheet-content {
  padding: 16px;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 60px; /* Added space for the fixed button */
}


.quantity-controls {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 16px;
}

.quantity-btn {
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 60px; /* Increased width */
}




.quantity-input {
  width: 80px; /* Maintain width */
  margin: 0 15px; /* Increased space between buttons and input */
  text-align: center;
  font-size: 16px;
  padding: 8px;
}

:root {
  --button-color: #0088cc; /* Telegram's blue color */
  --button-text-color: white;
  --hover-button-color: #007ab8; /* Slightly darker blue for hover state */
}

.option-btn {
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%; /* Make sure the button fills the container for option buttons */
}

.quantity-btn {
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 66%; /* Make sure the button fills the container for option buttons */
  margin: 0 10px; /* Adds margin to both sides of each button */

}
.option-btn:hover, .quantity-btn:hover {
  background-color: var(--hover-button-color);
}


 /* Bulk deal button style with hover effect */
.bulk-deal-btn {
  background-color: transparent; /* Keeps the background transparent */
  border: 1px solid var(--button-color); /* Outlined style with the primary button color */
  color: var(--button-color); /* Text color matching the border */
  margin-bottom: 8px; /* Space between each bulk deal button */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.bulk-deal-btn:last-child {
  margin-bottom: 0; /* Removes margin for the last button */
}

.bulk-deal-btn:hover, .bulk-deal-btn:active, .bulk-deal-btn:focus {
  background-color: var(--button-color); /* Changes background on hover/select */
  color: white; /* Changes text color on hover/select */
}

.done-btn {
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  padding: 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.done-btn:hover {
  background-color: var(--hover-button-color);
} 