.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 7px;
  width: 150px;
  /* height: 250px; */
  border: 2px solid var(--button-color);
  padding: 0.5rem 0.3rem;
  margin: 13px 10px;
  position: relative;
  border-radius: 5px;
  background-color: var(--background-color);
}

.card__title {
  font-weight: normal; /* Normal weight for the entire title */
  text-align: left; /* Align text to the start */
  font-size: 16px; /* Base font size */
  /* margin-top: 5px; Reduced margin */
  gap: 4px;
  display: flex;
  flex-flow: column;
  color: var(--text-color);
}

.card__title .product-name {
  font-weight: bold; /* Bold for the product name */
}

.card__price {
  text-align: left; /* Align text to the start */
  color: var(--text-color);
}

.image__container {
  width: 100px;
  height: 100px;
  margin: 0 auto; /* Center the image */
}

.image__container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card__divider {
  width: 100%;
  height: 1px;
  background-color: var(--button-color);
  margin: 1px 0; /* Reduced margin */
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card__badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: rgb(227, 56, 30);
  border-radius: 50%;
  transform: translate(20px, -20px) scale(1);
  color: #fff;
  font-weight: bold;
  text-align: center;
  border: 2px solid rgb(227, 56, 30);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  animation: scaleanimation 1s ease infinite;
  opacity: 0.8;
}

.card__badge--hidden {
  display: none;
}




