.payment-popup {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1001;
  max-width: 400px;
  width: 90%;
  height: 60%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.payment-popup .header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  background-color: var(--background-color); /* Ensure this uses the correct background color */
  z-index: 100;
  margin: auto;
}

.payment-popup .close-icon {
  cursor: pointer;
  color: var(--hint-color); /* Ensure this uses the correct text color */
}

.payment-popup .header h2 {
  font-size: 24px;
  color: var(--button-color);
}

.payment-popup ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  flex-grow: 1;
  max-height: 50vh; /* Adjust this value as needed */
}

/* Custom Scrollbar Styles */
.payment-popup ul::-webkit-scrollbar {
  width: 8px;
}

.payment-popup ul::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px;
}

.payment-popup ul::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

.payment-popup ul::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track background color */
}

.payment-popup ul li {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.payment-popup ul li.selected {
  background-color: #f0f0f0;
  color: black;
}

.payment-popup ul li span{
color: var(--text-color);
  font-size: 18px;
}
.payment-popup ul li.selected span{
color: black;
}

.payment-popup ul li img {
  width: 80px;
  height: 40px;
  margin-right: 10px;
}


.payment-popup button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.payment-popup button:hover {
  background-color: #0056b3;
}

.payment-popup h4{
  color: var(--button-color);
}

.account-number-input-container {
  padding: 10px;
  background-color: #f9f9f9;
  border-top: 1px solid #eee;
}

.phone-input-wrapper {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #ccc;
}

.country-code {
  font-size: 16px;
  margin-right: 5px;
}

.account-number-input {
  flex-grow: 1;
  padding: 8px;
  border: none;
  outline: none;
  border-radius: 0 5px 5px 0; /* Round the right corners only */
}
