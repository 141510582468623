.btn {
  padding: 0.6rem 0.8rem;
  font-size: 1.2rem;
  text-align: center;
  border: 0;
  outline: none;
  border-radius: 10px;
  width: 120px; /* Adjusted width for the card buttons */
  margin-left: 10px;
  box-shadow: 1px -3px 52px -5px rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: var(--button-color);
  color: var(--button-text-color);
}

.btn:active {
  transform: scale(0.98);
}

.btn.add,
.btn.buy,
.btn.remove,
.btn.checkout {
  background-color: var(--button-color); /* Use Telegram theme color */
}

.btn.add:hover,
.btn.buy:hover,
.btn.remove:hover,
.btn.checkout:hover {
  background-color: var(--button-color); /* Use Telegram theme color */
}
