.history_cards__container {
    width: 98%;
    display: flex;
    flex-flow: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 5px;
    padding-top: 80px;
}

.title{
    color: var(--button-color);
    text-align: center;
}