body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.cards__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 80px;
}

.heading {
  text-align: center;
  font-size: 24px;
  margin: 16px 0;
  color: var(--text-color);
}

.subheading {
  text-align: center;
  font-size: 18px;
  margin: 8px 0;
  color: var(--text-color);
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--background-color);
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.icons-box{
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
}

.search-input {
  flex: 1;
  max-width: 60%;
  padding: 10px;
  border: 1px solid var(--button-color);
  border-radius: 5px;
  outline: none;
  font-size: 16px;
  background-color: var(--background-color);
  color: var(--text-color);
}

.search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 46px;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 5px;
}

.history-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cart-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  padding: 2px;
}

.cart-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.search-icon {
  width: 30px;
  height: 30px;
}

.cart-counter-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: green;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.loading-animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Profile Container */
.profile-container {
  display: flex;
  align-items: center;
}

.profile-picture {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.profile-welcome span {
  font-size: 19px;
}

.profile-welcome .greeting {
  font-weight: normal;
  color: #888;
}

.profile-welcome .name {
  font-weight: bold;
  color: var(--button-color);
}
